import React, { useState, useRef, useEffect } from "react";
import { BiMenu } from "react-icons/bi"; // Example icon for menu
import { RiDashboardLine } from "react-icons/ri"; // Example icon for dashboard link
import { Link } from "react-router-dom";

const DropdownMenu = ({ options, setTheme, theme, user, isAdmin, isEditor}) => {
  const [isOpen, setIsOpen] = useState(false); // Dropdown state (open/closed)
  const dropdownRef = useRef(null); // Reference to the dropdown

  // Toggle dropdown open/close
  const toggleDropdown = () => {
    setIsOpen((prev) => !prev);
  };

  // Close dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  return (
    <li ref={dropdownRef} className="relative">
      <button
        type="button"
        onClick={toggleDropdown} // Toggle dropdown visibility
        className="text-4xl tracking-wide text-primary font-bold duration-200 bg-transparent outline-none cursor-pointer dark:text-gray-300 mt-1"
        style={{ listStyle: "none" }}
      >
        <BiMenu />
      </button>

      {isOpen && (
        <ul className="p-2 shadow menu dropdown-content z-[1] bg-slate-200 dark:text-gray-100 rounded-box w-52 mt-3 dark:bg-slate-600 absolute right-0">
          {options.map((opt, index) => (
            <button
              key={index}
              onClick={() => setTheme(opt?.text)}
              className={`block w-full ${
                theme === opt?.text && "text-sky-600"
              }`}
            >
              <h1 className="flex items-center gap-7 px-5 py-1.5 hover:bg-slate-300 mt-1 text-lg rounded-md">
                {opt?.icon}
                <p className="uppercase">{opt?.text}</p>
              </h1>
            </button>
          ))}

          {user?.uid && (
            <>
              {isAdmin && (
                <li>
                  <Link
                    to="/dashBoard"
                    aria-label="Dash Board"
                    title="Dash Board"
                    className="hover:bg-slate-300"
                  >
                    <p
                      className={`fo font-medium text-lg tracking-wide text-[#49465D] transition-colors duration-200 uppercase dark:text-gray-100 flex items-center gap-5 dark:hover:text-white`}
                    >
                      <RiDashboardLine className="text-2xl" />
                      Dash Board
                    </p>
                  </Link>
                </li>
              )}
              {isEditor && (
                <li>
                  <Link
                    to="/editorDashboard"
                    aria-label="Editor Dash Board"
                    title="Editor Dash Board"
                    className="hover:bg-slate-300"
                  >
                    <h1
                      className={`fo font-medium text-lg tracking-wide text-[#49465D] transition-colors duration-200 uppercase dark:text-gray-100 flex items-center gap-5 dark:hover:text-white`}
                    >
                      <RiDashboardLine className="text-2xl" />
                      Dash Board
                    </h1>
                  </Link>
                </li>
              )}
            </>
          )}
        </ul>
      )}
    </li>
  );
};

export default DropdownMenu;
