import React from "react";
import { PhotoProvider, PhotoView } from "react-photo-view";
import "react-photo-view/dist/react-photo-view.css";

// Mothers Day Luncheon

import mother1 from "../../../src/assets/Mother's Day/img-1.jpg";
import mother2 from "../../../src/assets/Mother's Day/img-2.jpg";
import mother3 from "../../../src/assets/Mother's Day/img-3.jpg";
import mother4 from "../../../src/assets/Mother's Day/img-4.jpg";
import mother5 from "../../../src/assets/Mother's Day/img-5.jpg";
import mother6 from "../../../src/assets/Mother's Day/img-6.jpg";
import mother7 from "../../../src/assets/Mother's Day/img-7.jpg";
import mother8 from "../../../src/assets/Mother's Day/img-8.jpg";
import mother9 from "../../../src/assets/Mother's Day/img-9.jpg";
import PageComponent from "../PageComponent/PageComponent";

const MotherDay = () => {
  return (
    <div className="min-h-screen dark:bg-slate-600">
      
      <PageComponent
        title="Mother's Day Luncheon - Cottage Home Care Services"
        description="Celebrate Mother's Day with Cottage Home Care Services at our annual Mother's Day Luncheon. This special event honors mothers and caregivers for their dedication and love. Join us for a delightful meal, heartfelt tributes, and entertainment as we show appreciation to the incredible women in our community across New York City, Nassau, Suffolk County, Westchester, and Albany."
        keywords="Mother's Day Luncheon, Cottage Home Care event, Mother's Day celebration, caregiver appreciation, mother appreciation event, community event, Mother's Day tribute, New York City, Nassau, Suffolk County, Westchester, Albany, home care event, community engagement, celebrating mothers"
      />

      <div className="mother-day min-h-[80vh] hidden md:block ">
        <h1 className="lg:pt-[55vh] pt-[60vh] text-center text-white  text-2xl md:text-5xl font-semibold">
          <span className="graduation-title px-4 py-2 advertise text-shadow">
            Mother's Day luncheon
          </span>
        </h1>
      </div>
      <div className="mother-day  block md:hidden">
        <h1 className="pt-[30vh] text-center text-white  text-2xl md:text-5xl font-semibold">
          <span className="graduation-title px-4 py-2 advertise text-shadow">
            Mother's Day luncheon
          </span>
        </h1>
      </div>
      <div className="lg:py-10 lg:w-[80%] w-[95%] mx-auto ">
        <div className="md:mb-8 md:pt-12 py-5 md:py-0">
          <h1 className="text-gray-700 text-center  text-xl  advertise md:text-4xl font-semibold dark:text-gray-100">
            Mother's Day luncheon
          </h1>
          <div className="flex justify-center ">
            <hr className="bg-primary px-5 md:mt-5 py-[1px] w-[100px]" />
          </div>
        </div>
        <div className="grid md:grid-cols-2 gap-7">
          <div class="grid grid-rows-3 grid-flow-col gap-7">
            <div
              class="row-span-1 col-span-4 lg:h-[300px] h-[200px]  overflow-hidden w-full cursor-pointer"
              data-aos="fade-down"
              data-aos-duration="1000"
            >
              <PhotoProvider>
                <PhotoView src={mother1}>
                  <img
                    src={mother1}
                    alt="img-1"
                    className="w-full h-[100%]  center shadow-md border-[1px] border-primary  object-cover custom-zoom"
                  />
                </PhotoView>
              </PhotoProvider>
            </div>
            <div
              class=" row-span-1 col-span-2 lg:h-[300px] h-[200px]  overflow-hidden w-full cursor-pointer "
              data-aos="fade-right"
              data-aos-duration="1200"
            >
              <PhotoProvider>
                <PhotoView src={mother2}>
                  <img
                    src={mother2}
                    alt="img2"
                    className="w-full h-[100%] shadow-md border-[1px] border-primary  object-cover custom-zoom"
                  ></img>
                </PhotoView>
              </PhotoProvider>
            </div>
            <div
              class="row-span-1 col-span-2 lg:h-[300px] h-[200px]  overflow-hidden w-full cursor-pointer "
              data-aos="fade-right"
              data-aos-duration="1400"
            >
              <PhotoProvider>
                <PhotoView src={mother3}>
                  <img
                    src={mother3}
                    alt="img3"
                    className="w-full h-[100%] shadow-md border-[1px] border-primary  object-cover custom-zoom"
                  />
                </PhotoView>
              </PhotoProvider>
            </div>
            <div
              class="row-span-2 col-span-2 lg:h-[628px] h-[428px]  overflow-hidden w-full cursor-pointer"
              data-aos="fade-up"
              data-aos-duration="1600"
            >
              <PhotoProvider>
                <PhotoView src={mother4}>
                  <img
                    src={mother4}
                    alt=""
                    className="w-full h-[100%] shadow-md border-[1px] border-primary cursor-pointer object-cover custom-zoom"
                  />
                </PhotoView>
              </PhotoProvider>
            </div>
          </div>

          <div class="grid grid-rows-3 grid-flow-col gap-7">
            <div
              class="row-span-1 col-span-2 lg:h-[300px] h-[200px]  overflow-hidden w-full cursor-pointe"
              data-aos="fade-down"
              data-aos-duration="1000"
            >
              <PhotoProvider>
                <PhotoView src={mother5}>
                  <img
                    src={mother5}
                    alt="img-1"
                    className="w-full h-[100%] shadow-md border-[1px] border-primary cursor-pointer object-cover custom-zoom "
                  />
                </PhotoView>
              </PhotoProvider>
            </div>
            <div
              class="row-span-1 col-span-4 lg:h-[300px] h-[200px]  overflow-hidden w-full cursor-pointe"
              data-aos="fade-left"
              data-aos-duration="1200"
            >
              <PhotoProvider>
                <PhotoView src={mother7}>
                  <img
                    src={mother7}
                    alt="img-1"
                    className="w-full h-[100%] object-cover custom-zoom center shadow-md border-[1px] border-primary cursor-pointer "
                  />
                </PhotoView>
              </PhotoProvider>
            </div>
            <div
              class="row-span-1 col-span-2 lg:h-[300px] h-[200px]  overflow-hidden w-full cursor-pointer "
              data-aos="fade-up"
              data-aos-duration="1400"
            >
              <PhotoProvider>
                <PhotoView src={mother6}>
                  <img
                    src={mother6}
                    alt="img-1"
                    className="w-full h-[100%] shadow-md border-[1px] border-primary cursor-pointer object-cover custom-zoom "
                  />
                </PhotoView>
              </PhotoProvider>
            </div>
            <div
              class="row-span-1 col-span-2 lg:h-[300px] h-[200px]  overflow-hidden w-full cursor-pointe "
              data-aos="fade-down"
              data-aos-duration="1400"
            >
              <PhotoProvider>
                <PhotoView src={mother8}>
                  <img
                    src={mother8}
                    alt="img-1"
                    className="w-full  h-[100%] shadow-md border-[1px] border-primary cursor-pointer object-cover custom-zoom"
                  />
                </PhotoView>
              </PhotoProvider>
            </div>
            <div
              class="row-span-1 col-span-2  lg:h-[300px] h-[200px]  overflow-hidden w-full cursor-pointer  "
              data-aos="fade-up"
              data-aos-duration="1600"
            >
              <PhotoProvider>
                <PhotoView src={mother9}>
                  <img
                    src={mother9}
                    alt="img-1"
                    className=" h-[100%] w-full shadow-md border-[1px] border-primary cursor-pointer object-cover custom-zoom"
                  />
                </PhotoView>
              </PhotoProvider>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MotherDay;
