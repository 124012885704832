import React, { useEffect, useState } from "react";
import { PhotoProvider, PhotoView } from "react-photo-view";
import "react-photo-view/dist/react-photo-view.css";
import PageComponent from "../PageComponent/PageComponent";
import { ClipLoader } from "react-spinners";

import img1 from "../../../src/assets/Breast_Cancer_Awarencess/img1.jpg";
import img2 from "../../../src/assets/Breast_Cancer_Awarencess/img3.jpg";
import img3 from "../../../src/assets/Breast_Cancer_Awarencess/img4.jpg";
import img4 from "../../../src/assets/Breast_Cancer_Awarencess/img15.jpg";
import img5 from "../../../src/assets/Breast_Cancer_Awarencess/img9.jpg";
import img6 from "../../../src/assets/Breast_Cancer_Awarencess/img2.jpg";
import img7 from "../../../src/assets/Breast_Cancer_Awarencess/img5.jpg";
import img8 from "../../../src/assets/Breast_Cancer_Awarencess/img8.jpg";
import img9 from "../../../src/assets/Breast_Cancer_Awarencess/img13.jpg";
import img10 from "../../../src/assets/Breast_Cancer_Awarencess/cover.webp";
import img11 from "../../../src/assets/Breast_Cancer_Awarencess/img14.jpg";
import img12 from "../../../src/assets/Breast_Cancer_Awarencess/img11.jpg";
import img13 from "../../../src/assets/Breast_Cancer_Awarencess/img17.jpg";
import img14 from "../../../src/assets/Breast_Cancer_Awarencess/img10.jpg";
import img15 from "../../../src/assets/Breast_Cancer_Awarencess/img8.jpg";
import img16 from "../../../src/assets/Breast_Cancer_Awarencess/img6.jpg";
import img17 from "../../../src/assets/Breast_Cancer_Awarencess/img12.jpg";
import img18 from "../../../src/assets/Breast_Cancer_Awarencess/img18.jpg";
import img19 from "../../../src/assets/Breast_Cancer_Awarencess/mobile.webp";
import img20 from "../../../src/assets/Breast_Cancer_Awarencess/img27.jpg";
import img21 from "../../../src/assets/Breast_Cancer_Awarencess/img24.jpg";
import img22 from "../../../src/assets/Breast_Cancer_Awarencess/img21.jpg";
import img23 from "../../../src/assets/Breast_Cancer_Awarencess/img19.jpg";
import img24 from "../../../src/assets/Breast_Cancer_Awarencess/img25.jpg";
import img25 from "../../../src/assets/Breast_Cancer_Awarencess/img28.jpg";
import img26 from "../../../src/assets/Breast_Cancer_Awarencess/img26.jpg";
import img27 from "../../../src/assets/Breast_Cancer_Awarencess/img20.jpg";
import img29 from "../../../src/assets/Breast_Cancer_Awarencess/img29.jpg";

const BreastCancerAwarencess = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const handleImageLoad = () => setLoading(false);
    const images = [img1, img2, img3, img4, img5, img6];
    let loadedImages = 0;

    images?.forEach((src) => {
      const img = new Image();
      img.src = src;
      img.onload = () => {
        loadedImages += 1;
        if (loadedImages === images.length) {
          handleImageLoad();
        }
      };
    });
  }, []);

  if (loading) {
    return (
      <div className="flex justify-center items-center min-h-screen dark:bg-slate-600">
        <ClipLoader
          color="#3498db"
          loading={loading}
          size={150}
          className="loader-large"
        />
      </div>
    );
  }

  return (
    <div className="min-h-screen dark:bg-slate-600 ">
      <PageComponent
        title="Breast Cancer Awareness Month 2024 - Cottage Home Care Services"
        description="At Cottage Home Care, we are deeply inspired by the strength, courage, and unwavering spirit of women, especially those who are bravely battling breast cancer. You are not just survivors—you are warriors, and your resilience empowers us all. 
        This Breast Cancer Awareness Month, we honor every woman who has faced this journey with grace, determination, and hope. You are a symbol of strength, not only for your families but for all of us. Your fight reminds us that even in the darkest moments, there is always hope and unity. 
        Together, we stand with you, we support you, and we are committed to raising awareness and spreading love. Let's keep lifting each other up, because when women support women, there’s nothing we can’t overcome."
        keywords="Breast Cancer Awareness, Cottage Home Care, breast cancer education, cancer prevention, early detection, community outreach, breast cancer support, health awareness events, breast cancer treatment, New York City, Nassau, Suffolk County, Westchester, Albany, cancer advocacy, support groups, healthcare awareness."
      />
      <div className="breast-cancer-awarencess min-h-[80vh] hidden md:block">
        <h1 className="lg:pt-[65vh] pt-[50vh] text-center text-white  text-2xl md:text-5xl font-semibold">
          <span className="graduation-title px-4 py-2 text-shadow">
            Breast Cancer Awareness Month - 2024
          </span>
        </h1>
      </div>
      <div className="breast-cancer-awarencess md:hidden block">
        <h1 className=" pt-[30vh] text-center text-white  text-2xl md:text-5xl font-semibold">
          <span className="graduation-title px-4 py-2">
            Breast Cancer Awareness Month - 2024
          </span>
        </h1>
      </div>

      <div className="md:py-5 lg:w-[80%] w-[95%] mx-auto ">
        <div className="lg:mb-8  py-5">
          <h1 className="text-gray-700 text-center  text-xl lobster md:text-4xl font-medium dark:text-gray-100 text-shadow">
            {" "}
            Breast Cancer Awareness Month - 2024
          </h1>
          <div className="flex justify-center ">
            <hr className="bg-primary px-5 md:mt-2 mt-2 py-[1px] w-[100px]" />
          </div>
        </div>
        <div className="grid md:grid-cols-2 gap-7">
          <div class="grid grid-rows-3 grid-flow-col gap-7">
            <div
              class="row-span-1 col-span-4 lg:h-[300px] h-[200px]  overflow-hidden w-full cursor-pointer hidden md:block"
              data-aos="fade-down"
              data-aos-duration="1000"
            >
              <PhotoProvider>
                <PhotoView src={img1}>
                  <img
                    src={img1}
                    alt="img-1"
                    className="w-full h-[100%]  center shadow-md border-[1px] border-primary  object-cover custom-zoom "
                  />
                </PhotoView>
              </PhotoProvider>
            </div>
            <div
              class="row-span-1 col-span-4 lg:h-[300px] h-[200px]  overflow-hidden w-full cursor-pointer block md:hidden"
              data-aos="fade-down"
              data-aos-duration="1000"
            >
              <PhotoProvider>
                <PhotoView src={img1}>
                  <img
                    src={img19}
                    alt="img-1"
                    className="w-full h-[100%]  center shadow-md border-[1px] border-primary  object-cover custom-zoom "
                  />
                </PhotoView>
              </PhotoProvider>
            </div>
            <div
              class=" row-span-1 col-span-2 lg:h-[300px] h-[200px]  overflow-hidden w-full cursor-pointer "
              data-aos="fade-right"
              data-aos-duration="1200"
            >
              <PhotoProvider>
                <PhotoView src={img2}>
                  <img
                    src={img2}
                    alt="img2"
                    className="w-full h-[100%] shadow-md border-[1px] border-primary  object-cover custom-zoom"
                  ></img>
                </PhotoView>
              </PhotoProvider>
            </div>
            <div
              class="row-span-1 col-span-2 lg:h-[300px] h-[200px]  overflow-hidden w-full cursor-pointer "
              data-aos="fade-right"
              data-aos-duration="1400"
            >
              <PhotoProvider>
                <PhotoView src={img3}>
                  <img
                    src={img3}
                    alt="img3"
                    className="w-full h-[100%] shadow-md border-[1px] border-primary  object-cover custom-zoom"
                  />
                </PhotoView>
              </PhotoProvider>
            </div>
            <div
              class="row-span-2 col-span-2 lg:h-[628px] h-[428px]  overflow-hidden w-full cursor-pointer"
              data-aos="fade-up"
              data-aos-duration="1600"
            >
              <PhotoProvider>
                <PhotoView src={img4}>
                  <img
                    src={img4}
                    alt=""
                    className="w-full h-[100%] shadow-md border-[1px] border-primary cursor-pointer object-cover custom-zoom"
                  />
                </PhotoView>
              </PhotoProvider>
            </div>
          </div>

          <div class="grid grid-rows-3 grid-flow-col gap-7">
            <div
              class="row-span-1 col-span-2 lg:h-[300px] h-[200px]  overflow-hidden w-full cursor-pointe"
              data-aos="fade-down"
              data-aos-duration="1000"
            >
              <PhotoProvider>
                <PhotoView src={img5}>
                  <img
                    src={img5}
                    alt="img-1"
                    className="w-full h-[100%] shadow-md border-[1px] border-primary cursor-pointer object-cover custom-zoom "
                  />
                </PhotoView>
              </PhotoProvider>
            </div>
            <div
              class="row-span-1 col-span-4 lg:h-[300px] h-[200px]  overflow-hidden w-full cursor-pointe"
              data-aos="fade-left"
              data-aos-duration="1200"
            >
              <PhotoProvider>
                <PhotoView src={img6}>
                  <img
                    src={img6}
                    alt="img-1"
                    className="w-full h-[100%] object-cover custom-zoom center shadow-md border-[1px] border-primary cursor-pointer "
                  />
                </PhotoView>
              </PhotoProvider>
            </div>
            <div
              class="row-span-1 col-span-2 lg:h-[300px] h-[200px]  overflow-hidden w-full cursor-pointer "
              data-aos="fade-up"
              data-aos-duration="1400"
            >
              <PhotoProvider>
                <PhotoView src={img7}>
                  <img
                    src={img7}
                    alt="img-1"
                    className="w-full h-[100%] shadow-md border-[1px] border-primary cursor-pointer object-cover custom-zoom "
                  />
                </PhotoView>
              </PhotoProvider>
            </div>
            <div
              class="row-span-1 col-span-2 lg:h-[300px] h-[200px]  overflow-hidden w-full cursor-pointe "
              data-aos="fade-down"
              data-aos-duration="1400"
            >
              <PhotoProvider>
                <PhotoView src={img8}>
                  <img
                    src={img8}
                    alt="img-1"
                    className="w-full  h-[100%] shadow-md border-[1px] border-primary cursor-pointer object-cover custom-zoom"
                  />
                </PhotoView>
              </PhotoProvider>
            </div>
            <div
              class="row-span-1 col-span-2  lg:h-[300px] h-[200px]  overflow-hidden w-full cursor-pointer  "
              data-aos="fade-up"
              data-aos-duration="1600"
            >
              <PhotoProvider>
                <PhotoView src={img9}>
                  <img
                    src={img9}
                    alt="img-1"
                    className=" h-[100%] w-full shadow-md border-[1px] border-primary cursor-pointer object-cover custom-zoom"
                  />
                </PhotoView>
              </PhotoProvider>
            </div>
          </div>
        </div>
        <div className="grid md:grid-cols-2 gap-7 mt-[28px]">
          <div class="grid grid-rows-3 grid-flow-col gap-7">
            <div
              class="row-span-1 col-span-4 lg:h-[300px] h-[200px]  overflow-hidden w-full cursor-pointer"
              data-aos="fade-down"
              data-aos-duration="1000"
            >
              <PhotoProvider>
                <PhotoView src={img10}>
                  <img
                    src={img10}
                    alt="img-1"
                    className="w-full h-[100%] shadow-md border-[1px] border-primary  object-cover custom-zoom"
                  />
                </PhotoView>
              </PhotoProvider>
            </div>
            <div
              class=" row-span-1 col-span-2 lg:h-[300px] h-[200px]  overflow-hidden w-full cursor-pointer "
              data-aos="fade-right"
              data-aos-duration="1200"
            >
              <PhotoProvider>
                <PhotoView src={img11}>
                  <img
                    src={img11}
                    alt="img2"
                    className="w-full h-[100%] shadow-md border-[1px] border-primary  object-cover custom-zoom"
                  ></img>
                </PhotoView>
              </PhotoProvider>
            </div>
            <div
              class="row-span-1 col-span-2 lg:h-[300px] h-[200px]  overflow-hidden w-full cursor-pointer "
              data-aos="fade-right"
              data-aos-duration="1400"
            >
              <PhotoProvider>
                <PhotoView src={img12}>
                  <img
                    src={img12}
                    alt="img3"
                    className="w-full h-[100%] shadow-md border-[1px] border-primary  object-cover custom-zoom"
                  />
                </PhotoView>
              </PhotoProvider>
            </div>
            <div
              class="row-span-2 col-span-2 lg:h-[628px] h-[428px]  overflow-hidden w-full cursor-pointer"
              data-aos="fade-up"
              data-aos-duration="1600"
            >
              <PhotoProvider>
                <PhotoView src={img13}>
                  <img
                    src={img13}
                    alt=""
                    className="w-full h-[100%] shadow-md border-[1px] border-primary cursor-pointer object-cover custom-zoom"
                  />
                </PhotoView>
              </PhotoProvider>
            </div>
          </div>

          <div class="grid grid-rows-3 grid-flow-col gap-7">
            <div
              class="row-span-1 col-span-2 lg:h-[300px] h-[200px]  overflow-hidden w-full cursor-pointer"
              data-aos="fade-down"
              data-aos-duration="1000"
            >
              <PhotoProvider>
                <PhotoView src={img14}>
                  <img
                    src={img14}
                    alt="img-1"
                    className="w-full h-[100%] shadow-md border-[1px] border-primary cursor-pointer object-cover custom-zoom"
                  />
                </PhotoView>
              </PhotoProvider>
            </div>
            <div
              class="row-span-1 col-span-4 lg:h-[300px] h-[200px]  overflow-hidden w-full cursor-pointer"
              data-aos="fade-left"
              data-aos-duration="1200"
            >
              <PhotoProvider>
                <PhotoView src={img15}>
                  <img
                    src={img15}
                    alt="img-1"
                    className="w-full h-[100%] object-cover custom-zoom center shadow-md border-[1px] border-primary cursor-pointer  "
                  />
                </PhotoView>
              </PhotoProvider>
            </div>
            <div
              class="row-span-1 col-span-2 lg:h-[300px] h-[200px]  overflow-hidden w-full cursor-pointer "
              data-aos="fade-up"
              data-aos-duration="1400"
            >
              <PhotoProvider>
                <PhotoView src={img16}>
                  <img
                    src={img16}
                    alt="img-1"
                    className="w-full h-[100%] object-cover custom-zoom center shadow-md border-[1px] border-primary cursor-pointer "
                  />
                </PhotoView>
              </PhotoProvider>
            </div>
            <div
              class="row-span-1 col-span-2 lg:h-[300px] h-[200px]  overflow-hidden w-full cursor-pointe "
              data-aos="fade-down"
              data-aos-duration="1400"
            >
              <PhotoProvider>
                <PhotoView src={img17}>
                  <img
                    src={img17}
                    alt="img-1"
                    className="w-full  h-[100%] shadow-md border-[1px] border-primary cursor-pointer object-cover custom-zoom"
                  />
                </PhotoView>
              </PhotoProvider>
            </div>
            <div
              class="row-span-1 col-span-2  lg:h-[300px] h-[200px]  overflow-hidden w-full cursor-pointer  "
              data-aos="fade-up"
              data-aos-duration="1600"
            >
              <PhotoProvider>
                <PhotoView src={img18}>
                  <img
                    src={img18}
                    alt="img-18"
                    className=" h-[100%] w-full shadow-md border-[1px] border-primary cursor-pointer object-cover custom-zoom"
                  />
                </PhotoView>
              </PhotoProvider>
            </div>
          </div>
        </div>
        <div className="grid md:grid-cols-2 gap-7 mt-[28px]">
          <div class="grid grid-rows-3 grid-flow-col gap-7">
            <div
              class="row-span-1 col-span-4 lg:h-[300px] h-[200px]  overflow-hidden w-full cursor-pointer"
              data-aos="fade-down"
              data-aos-duration="1000"
            >
              <PhotoProvider>
                <PhotoView src={img20}>
                  <img
                    src={img20}
                    alt="img-1"
                    className="w-full h-[100%] shadow-md border-[1px] border-primary  object-cover custom-zoom"
                  />
                </PhotoView>
              </PhotoProvider>
            </div>
            <div
              class=" row-span-1 col-span-2 lg:h-[300px] h-[200px]  overflow-hidden w-full cursor-pointer "
              data-aos="fade-right"
              data-aos-duration="1200"
            >
              <PhotoProvider>
                <PhotoView src={img21}>
                  <img
                    src={img21}
                    alt="img2"
                    className="w-full h-[100%] shadow-md border-[1px] border-primary  object-cover custom-zoom"
                  ></img>
                </PhotoView>
              </PhotoProvider>
            </div>
            <div
              class="row-span-1 col-span-2 lg:h-[300px] h-[200px]  overflow-hidden w-full cursor-pointer "
              data-aos="fade-right"
              data-aos-duration="1400"
            >
              <PhotoProvider>
                <PhotoView src={img22}>
                  <img
                    src={img22}
                    alt="img3"
                    className="w-full h-[100%] shadow-md border-[1px] border-primary  object-cover custom-zoom"
                  />
                </PhotoView>
              </PhotoProvider>
            </div>
            <div
              class="row-span-2 col-span-2 lg:h-[628px] h-[428px]  overflow-hidden w-full cursor-pointer"
              data-aos="fade-up"
              data-aos-duration="1600"
            >
              <PhotoProvider>
                <PhotoView src={img23}>
                  <img
                    src={img23}
                    alt=""
                    className="w-full h-[100%] shadow-md border-[1px] border-primary cursor-pointer object-cover custom-zoom"
                  />
                </PhotoView>
              </PhotoProvider>
            </div>
          </div>

          <div class="grid grid-rows-3 grid-flow-col gap-7">
            <div
              class="row-span-1 col-span-2 lg:h-[300px] h-[200px]  overflow-hidden w-full cursor-pointer"
              data-aos="fade-down"
              data-aos-duration="1000"
            >
              <PhotoProvider>
                <PhotoView src={img24}>
                  <img
                    src={img24}
                    alt="img-1"
                    className="w-full h-[100%] shadow-md border-[1px] border-primary cursor-pointer object-cover custom-zoom"
                  />
                </PhotoView>
              </PhotoProvider>
            </div>
            <div
              class="row-span-1 col-span-4 lg:h-[300px] h-[200px]  overflow-hidden w-full cursor-pointer"
              data-aos="fade-left"
              data-aos-duration="1200"
            >
              <PhotoProvider>
                <PhotoView src={img25}>
                  <img
                    src={img25}
                    alt="img-1"
                    className="w-full h-[100%] object-cover custom-zoom center shadow-md border-[1px] border-primary cursor-pointer  "
                  />
                </PhotoView>
              </PhotoProvider>
            </div>
            <div
              class="row-span-1 col-span-2 lg:h-[300px] h-[200px]  overflow-hidden w-full cursor-pointer "
              data-aos="fade-up"
              data-aos-duration="1400"
            >
              <PhotoProvider>
                <PhotoView src={img26}>
                  <img
                    src={img26}
                    alt="img-1"
                    className="w-full h-[100%] object-cover custom-zoom center shadow-md border-[1px] border-primary cursor-pointer "
                  />
                </PhotoView>
              </PhotoProvider>
            </div>
            <div
              class="row-span-1 col-span-2 lg:h-[300px] h-[200px]  overflow-hidden w-full cursor-pointe "
              data-aos="fade-down"
              data-aos-duration="1400"
            >
              <PhotoProvider>
                <PhotoView src={img27}>
                  <img
                    src={img27}
                    alt="img-1"
                    className="w-full  h-[100%] shadow-md border-[1px] border-primary cursor-pointer object-cover custom-zoom"
                  />
                </PhotoView>
              </PhotoProvider>
            </div>
            <div
              class="row-span-1 col-span-2  lg:h-[300px] h-[200px]  overflow-hidden w-full cursor-pointer  "
              data-aos="fade-up"
              data-aos-duration="1600"
            >
              <PhotoProvider>
                <PhotoView src={img29}>
                  <img
                    src={img29}
                    alt="img-18"
                    className=" h-[100%] w-full shadow-md border-[1px] border-primary cursor-pointer object-cover custom-zoom"
                  />
                </PhotoView>
              </PhotoProvider>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BreastCancerAwarencess;
