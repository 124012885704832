import React, { Suspense, } from "react";
import nursePic from "../../assets/navin&sova.jpg";
import "./PastEvent.css";
import { Link } from "react-router-dom";

// school supply giveaway

import toyDistribution from '../../assets/christmas-toy/card-cover.jpg'
import christmasParty from '../../assets/christmas-party/christmas-cover-photo.jpg'
import schoolSupply from '../../assets/Backpack School GIveway/backPack-cover.jpg'
import celebrityBrunch from '../../assets/celebrity-brunch/img-1.jpg'
import motherDay from "../../../src/assets/Mother's Day/img-1.jpg";
import brestCancer from "../../../src/assets/Breast_Cancer_Awarencess/img1.jpg";


////////////////////////////////////////////////////////

import video1 from "../../../src/assets/Videos/1.mp4";
import video2 from "../../../src/assets/Videos/2.mp4";
import video3 from "../../../src/assets/Videos/3.mp4";
import video4 from "../../../src/assets/Videos/4.mp4";

///////////////////////////////////////////////////////


import Chat from "../Shared/Chat/Chat";
import { BsArrowRight } from "react-icons/bs";
import SkeletonLoading from "../Shared/Loading/SkeletonLoading";
import PageComponent from "../PageComponent/PageComponent";

const Placeholder = () => <div><SkeletonLoading/></div>;
const LazyBanner = React.lazy(() => import('./PastEventBanner'));

const PastEvent = () => {



  const pictures = [
    {
      picture: video1,
      height: "560px",
      title: "Phagwah Celebrations",
      link: "https://www.facebook.com/cottageHC/videos/285922064453590",
      id: "little-div",
    },
    {
      picture: video2,
      height: "600px",
      title: "CEO Gifts Staff Mercedes Benz",
      link: "https://www.facebook.com/cottageHC/videos/337247038906085",
      id: "big-div",
    },
    {
      picture: video3,
      height: "560px",
      title: "Mother's Day Luncheon ",
      link: "https://cottagehomecare.com/pastEvent",
      id: "little-div",
    },
    {
      picture: video4,
      height: "600px",
      title: "Dedicated Staff Gifted Lexus ",
      link: "https://fb.watch/rQLDhBWTlY",
      id: "big-div",
    },
  ];


  const events = [
    {
      img: brestCancer,
      title: "Breast Cancer Awareness Month 2024",
      path: 'breast-cancer-awarencess'

  },
    {
      img: toyDistribution,
      title: "Christmas Toys Giveaway",
      path: 'toys-giveaway'

  },
    {
      img: christmasParty,
      title: "Christmas Party 2023",
      path: 'christmas-party'

  },
    {
      img: schoolSupply,
      title: "School Supplies Giveaway",
      path: 'school-supply'

  },
  {
      img: celebrityBrunch ,
      title: "Cottage  Celebrity Brunch",
      path: "celebrity-branch"

  },
  {
      img: motherDay,
      title: "Mother's Day luncheon",
      path: 'mother-day'

  },
  

  ]

  
  

  return (
    <div className="dark:bg-slate-600">
      
      <PageComponent
        title="Past Events - Cottage Home Care Services"
        description="Explore our past events and activities at Cottage Home Care Services. From community outreach programs to informative webinars, see how we engage and support our community. Discover highlights, photos, and key takeaways from our past events."
        keywords="Cottage Home Care past events, community outreach programs, healthcare webinars, event highlights, home care activities, community support events, past event photos, event key takeaways, Cottage Home Care community engagement"
      />
   
   <Suspense fallback={<Placeholder />}>
        {/* Lazy-loaded banner */}
        <LazyBanner />
      </Suspense>
     
      <div>
        <div className="md:flex justify-between  items-center dark:bg-slate-800 ">
          <div
            className="md:w-[50%]  mx-auto "
            data-aos="fade-right"
            data-aos-duration="2000"
          >
            <div className="w-[90%] mx-auto ">
              <div className="rounded-md  mr-auto ">
                <div className=" pb-8 pt-5 md:mt-0">
                  <h4
                    className=" text-start md:text-5xl text-[26px] font-medium md:font-bold text-gray-600   md:px-0 mt-2  pt-serif  dark:text-gray-300 lg:max-w-[620px] medium-device inline-block "
                    id="empowering"
                  >
                    Empowering & Educating
                    <svg
                      class="nectar-scribble basic-underline"
                      role="presentation"
                      viewBox="-400 -55 730 60"
                      preserveAspectRatio="none"
                    >
                      <path
                        style={{ "animation-duration": "0s" }}
                        d="m -383.25 -6 c 55.25 -22 130.75 -33.5 293.25 -38 c 54.5 -0.5 195 -2.5 401 15"
                        stroke="#FFA500"
                        pathLength="1"
                        stroke-width="6"
                        fill="none"
                      ></path>
                    </svg>
                  </h4>
                  <h4 className=" text-start md:text-5xl text-[20px] font-medium md:font-bold text-gray-600 pt-serif dark:text-gray-300 medium-device ">
                    {" "}
                    Building stronger Communities
                  </h4>
                </div>
              </div>
              <p className="text-2xl font-semibold text-primary ">
                Our Mission
              </p>
              <p className="mt-5 leading-relaxed md:text-lg font-medium text-justify text-gray-600 tracking-normal dark:text-gray-200">
                At Cottage Home Care Services, our mission is to enrich the
                lives of individuals and strengthen our community through
                meaningful and impactful initiatives.
              </p>
              <p className="mt-3 leading-relaxed md:text-lg font-medium text-justify text-gray-600 tracking-normal dark:text-gray-200">
                We firmly believe in the power of giving and strive to make a
                lasting difference by focusing on initiatives that align with
                our community's needs. Together, we can create a stronger
                community built on compassion, empathy, and shared prosperity.
              </p>
            </div>
          </div>
          <div
            className="z-10 md:-mt-8 "
            data-aos="fade-up"
            data-aos-duration="2000"
          >
            <img src={nursePic} alt="nurse" w='100' h='100'/>
          </div>
        </div>
      </div>



      <div>
        <div className="grid md:grid-cols-2 lg:grid-cols-4 items-end -mt-[40px] relative">
          {pictures?.map((picture, index) => (
            <a href={picture?.link} target="_blank"
            rel="noreferrer noopener"
            
            key={index} className="block">
              <div
                key={index}
                className={`z-10 card-zoom relative flex items-center justify-center overflow-hidden ${picture?.id}`}
                style={{
                  minHeight: `${picture?.height}`,
                  position: "relative", // Ensure the container has a relative position
                }}
                id="event"
              >
                <video
                  src={picture?.picture}
                  autoPlay
                  loop
                  muted
                  playsInline // Add this attribute for better compatibility
                  className="absolute top-0 left-0 w-full h-full object-cover custom-zoom "
                >
                  {/* Add additional source elements for different video formats if needed */}
                </video>
                <div
                  className="absolute top-0 left-0 w-full h-full"
                  style={{
                    backgroundImage:
                      "linear-eveient(90deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.5))",
                  }}
                ></div>

                <div className="text-gray-100  md:h-28 w-[95%] md:w-[85%] mx-auto absolute md:bottom-12 bottom-8  ">
                  <div className="">
                    <h1 className="md:text-3xl text-2xl font-semibold">
                      {picture?.title}
                    </h1>
                    <p className="md:mt-3  mt-2 font-semibold flex items-center gap-2">
                      LEARN MORE <BsArrowRight className="text-2xl" />
                    </p>
                  </div>
                </div>
              </div>
            </a>
          ))}
        </div>
      </div>

      {/* event cards start */}

<h1 className="text-gray-700 text-center  text-xl  advertise md:text-4xl font-semibold dark:text-gray-100 mt-12">Cottage Photo Album</h1>
      <div className='lg:w-[75%] mx-auto py-8 w-[95%] ' id='event-card'>

                <div className='grid grid-cols-1  md:grid-cols-2 lg:grid-cols-3 md:gap-10 gap-7 '>
                    {
                        events?.map((eve, index) => <Link

                            key={index}

                            to={`/${eve?.path}`}>

                            <div  data-aos="zoom-in" data-aos-duration="1000">

                                <div className='relative overflow-hidden h-64 md:h-48 xl:h-64'>
                                    <img src={eve?.img} alt={eve?.img} className='w-full object-cover h-fit transition duration-500 transform hover:scale-105'  w='100' h='100'
                                    loading='lazy'/>
                                    <div className='absolute inset-0 bg-gradient-to-b from-black via-black to-black opacity-0 hover:opacity-50 transition duration-300 flex items-center justify-center'>
                                        <p className='text-white text-center text-lg font-semibold'>View Your Gallery</p>
                                    </div>
                                </div>
                                

                                <h3 className='uppercase text-center mt-1 font-medium tracking-widest dark:text-gray-100 text-gray-600 text-lg'>
                                    {eve?.title}
                                </h3>

                            </div>



                        </Link>)
                    }

                </div>


            </div>


      {/* event cards end  */}




     
      <Chat/>
    </div>
  );
};

export default PastEvent;
