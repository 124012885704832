import React, { useState } from "react";

import "./HelpDesk.css";
import { GiSandsOfTime } from "react-icons/gi";
import { BsTable } from "react-icons/bs";
import { AiOutlineFileSearch } from "react-icons/ai";
import GeneralRequest from "./GeneralRequest";
import PayStubAccess from "./PayStubAccess";
import PayrollInquiries from "./PayrollInquiries";
import RequestSupplies from "./RequestSupplies";
import RequestPto from "./RequestPto";

import OverlayLoading from "../../OverlayLoading/OverlayLoading";
import { Link } from "react-router-dom";
import Chat from "../../Shared/Chat/Chat";
import PageComponent from "../../PageComponent/PageComponent";

const HelpDesk = () => {
  const [loading, setLoading] = useState(false);

  const stuffs = [
    {
      icon: <GiSandsOfTime />,
      name: "EVV Clock In/Out",
      description: "Instructions to clock in and out of EVV",
      path: "clockInOut",
    },
    {
      icon: <BsTable />,
      name: "View Your PTO",
      description: "Click here to view your paid time off",
      link: "https://hcm.viventium.com/apps/account/viventium/login",
    },
    {
      icon: <AiOutlineFileSearch />,
      name: "View Your Pay Stub",
      description: "View your latest pay stub",
      link: "https://hcm.viventium.com/apps/account/viventium/login",
    },
  ];
  
  return (
    <div className="min-h-screen dark:bg-slate-600 ">
      <PageComponent
        title="Help Desk - Cottage Home Care Services"
        description="The Help Desk at Cottage Home Care Services provides comprehensive support for our staff. Easily manage your work-related tasks including Clock In/Out, access PayStubs, manage Paid Time Off (PTO), submit General Requests, view Payroll details, and Request Supplies. Our goal is to streamline administrative processes to ensure a smooth operational flow."
        keywords="Cottage Home Care Help Desk, employee support, clock in, clock out, PayStub access, PTO management, general requests, payroll information, request supplies, administrative help, healthcare staff support"
      />

      {loading && <OverlayLoading />}

      <div className="help-desk-cover">
        <h1 className="text-center w-full h-full flex justify-center items-center text-white text-3xl md:text-3xl xl:text-5xl font-medium text-shadow ">
          Help Desk
        </h1>
      </div>

      <div className="grid md:grid-cols-3 mx-auto w-[95%] md:w-[90%] 2xl:w-[75%]  py-6  gap-10 ">
        {stuffs?.map((stuff, index) => (
          <div key={index}>
            <div
              style={{ boxShadow: "0 0 10px 3px rgba(0, 0, 0, 0.3)" }}
              className=" rounded-lg py-10 bg-hov3 stuff-div dark:bg-slate-800 "
            >
              <h1 className="text-3xl md:text-5xl flex justify-center items-center w-20 md:w-[90px] h-20 md:h-[90px]  mx-auto rounded-full bg-primary text-white stuff-icon">
                {stuff?.icon}
              </h1>

              <h1 className="text-center mt-5 font-semibold text-xl md:text-2xl dark:text-gray-100">
                {stuff?.name}
              </h1>

              <p className="text-center mt-2 md:text-lg 2xl:text-xl dark:text-gray-100">
                {stuff?.description}
              </p>

              <div className="text-center mt-8">
                {stuff?.link ? (
                  <a
                    href={stuff?.link}
                    target="_blank"
                    className="px-7 p-3.5 rounded-full font-semibold uppercase bg-primary text-white shadow-md stuff-button "
                  >
                    {" "}
                    view
                  </a>
                ) : (
                  <Link
                    to={`/${stuff?.path}`}
                    className="px-7 p-3.5 rounded-full font-semibold uppercase bg-primary text-white shadow-md stuff-button "
                  >
                    {" "}
                    view
                  </Link>
                )}
              </div>
            </div>
          </div>
        ))}
      </div>

      <div className="grid md:grid-cols-2 mx-auto w-[95%] md:w-[90%] 2xl:w-[75%] pt-8 pb-6 gap-10 2xl:gap-20 ">
        <div>
          <GeneralRequest loading={loading} setLoading={setLoading} />
        </div>

        <div>
          <PayStubAccess loading={loading} setLoading={setLoading} />
        </div>
      </div>

      <div className="grid md:grid-cols-2 lg:grid-cols-3 mx-auto w-[95%] md:w-[90%] 2xl:w-[75%] pt-8 pb-6 md:pb-10 gap-10 2xl:gap-10 ">
        <div>
          <PayrollInquiries loading={loading} setLoading={setLoading} />
        </div>

        <div>
          <RequestSupplies loading={loading} setLoading={setLoading} />
        </div>

        <div>
          <RequestPto loading={loading} setLoading={setLoading} />
        </div>
      </div>

      <Chat />
    </div>
  );
};

export default HelpDesk;
