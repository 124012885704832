import React from 'react';
import { FaHandHoldingMedical } from "react-icons/fa6";
import { MdOutlinePersonalInjury } from "react-icons/md";
import img1 from '../../../../assets/benefit-card.jpg';
import img2 from '../../../../assets/medical-condition.png';
import img3 from '../../../../assets/living-assistance.png';
import img4 from '../../../../assets/new-york-logo.png';
import CdpapModal from '../../../CdpapModal/CdpapModal';
import Chat from '../../../Shared/Chat/Chat';
import PageComponent from '../../../PageComponent/PageComponent';


import './Cdpap.css';
import LazyLoadVideo from '../../../LazyloadVideo.js/LazyLoadVideo';

const Cdpap = () => {
    const consumer = [
        "Choosing their own caregivers.",
        "Providing guidance and training to the caregivers.",
        "Developing a care plan with a healthcare professional.",
        "Manage and coordinate the caregiver's schedule.",
        "Stay in good communication with Medicaid and the Agency.",
    ];

    const agency = [
        "Manage payroll, including wages and taxes, for caregivers.",
        "Monitor and ensure adherence to the agreed-upon care plan.",
        "Provide continuous support and supervision to the caregivers.",
        "Ensure all parties are in compliance with all relevant regulations and requirements.",
    ];

    const eligibilities = [
        {
            img: img1,
            type: "card",
            description2: [
                "You must have Medicaid.",
                "(We can help you apply if you don't!)"
            ],
        },
        {
            img: img2,
            description: "You have a present medical condition."
        },
        {
            img: img3,
            description: "You require some/full assistance with daily living situations."
        },
        {
            img: img4,
            description: "You live in New York State"
        },
    ];

    return (
        <div className='min-h-screen dark:bg-slate-600'>
            <PageComponent 
                title="CDPAP - Cottage Home Care Services" 
                description="CDPAP, Earn up to $21.09 per hour in New York City"
                keywords="CDPAP, Consumer Directed Personal Assistance Program, home care, New York City, earn $21.09 per hour, personal assistance, caregiver jobs, senior care, healthcare jobs"
            />

            <div className='singleService-banner min-h-[40vh]'>
                <div className='pt-[5vh] md:pt-[50px] md:max-w-lg lg:ml-[3%] 2xl:ml-[5%]'>
                    <div className='space-y-2 pl-2 md:pl-0'>
                        <h1 className='text-center text-2xl xl:text-3xl 2xl:text-4xl text-white font-bold text-shadow'>CDPAP</h1>
                        <h1 className='text-center text-2xl text-white font-bold text-shadow block md:hidden'>
                        Get Optimum Pay Rates
                        </h1>
                        <h1 className='text-center text-2xl xl:text-3xl 2xl:text-4xl text-white font-bold text-shadow hidden md:block'>
                            Get Optimum Pay Rates
                        </h1>
                        <h1 className='text-center text-2xl xl:text-3xl 2xl:text-4xl text-white font-bold text-shadow'>
                            (New York City)
                        </h1>
                    </div>
                </div>
                <div className='flex justify-center md:justify-start pl-2 my-2 lg:ml-[17%] 2xl:ml-[13%] md:mt-5'>
                    <label
                        htmlFor="cdpap-form"
                        className="cursor-pointer 2xl:text-lg font-medium md:font-semibold px-2.5 py-1.5 2xl:px-4 2xl:py-3 bg-primary border-b-4 border-r-4 border-white text-white shadow-md rounded-md text-base"
                    >
                        Contact Us
                    </label>
                </div>
            </div>

            <div className='w-[95%] lg:w-[60%] mx-auto mt-10 dark:text-gray-100' id='cdpap-section'>
                <h1 className='roboto-condensend text-xl font-medium md:text-3xl md:leading-10 text-justify auto-hyphen'>
                Cottage Home Care Services is approved to assist you with the Consumer Directed Personal Assistant Program (CDPAP) and help you to start earning at optimum pay rates in New York City!
                </h1>
                <p className='mt-5 leading-relaxed roboto md:text-lg text-justify auto-hyphen'>
                    This unique program allows the consumer to administer and assume responsibility for their own care. The flexibility of this program and the way in which it is administered is what makes it so unique. It promotes independence and gives the consumer a true sense of involvement in the decision making process with his/her own care.
                </p>
            </div>

            <div className='w-[95%] lg:w-[70%] mx-auto mt-10'>
                <div className='grid grid-cols-1 md:grid-cols-2 lg:gap-8 gap-2'>
                    <div className='text-center w-[95%] md:w-[90%] mx-auto cdpap-point'>
                        <h3 className='text-xl lg:text-2xl font-medium bg-primary py-3 text-white'>
                            The consumer is responsible for:
                        </h3>
                        <ul className='text-start mb-10 mt-5'>
                            {consumer.map((con, index) => (
                                <li
                                    key={index}
                                    className='my-3 text-lg px-4 py-3 shadow-md hover:bg-primary hover:text-white flex items-center gap-2 dark:text-white dark:bg-slate-500'
                                >
                                    <MdOutlinePersonalInjury className='text-[#F48221] text-xl' />
                                    {con}
                                </li>
                            ))}
                        </ul>
                    </div>

                    <div className='text-center w-[95%] md:w-[90%] mx-auto cdpap-point'>
                        <h3 className='text-xl lg:text-2xl font-medium bg-[#164273] py-3 text-white'>
                            The agency is responsible for:
                        </h3>
                        <ul className='text-start mb-10 mt-5'>
                            {agency.map((agn, index) => (
                                <li
                                    key={index}
                                    className='my-3 text-lg px-4 py-3 shadow-md hover:bg-[#164273] hover:text-white flex items-center gap-2 dark:text-white text-justify hyphens-auto dark:bg-slate-500'
                                >
                                    <FaHandHoldingMedical className='text-[#F48221]' />
                                    {agn}
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>

            <div>
                <h3 className='dark:text-gray-100 w-full text-lg md:text-3xl text-center lobster mt-5'>
                    Eligibility for a CDPAP program
                </h3>
                <div className="flex justify-center">
                    <hr className="bg-primary px-5 mt-2 py-[1px] w-[100px]" />
                </div>

                <div className='grid lg:grid-cols-6 gap-7 md:w-[85%] w-[95%] mx-auto py-10 items-center' id='cdpap-points'>
                    <div className='col-span-5 lg:col-span-3'>
                        <div className='my-3 md:-ml-[3%]'>
                            {eligibilities?.map((eligible, index) => (
                                <div
                                    key={index}
                                    className='my-2'
                                    data-aos="fade-up"
                                    data-aos-duration={`${index * 300}`}
                                >
                                    <div className='flex items-center'>
                                        <div className='bg-primary rounded-full h-[120px] w-[120px] flex justify-center items-center z-10 mx-auto'>
                                            <div className='flex justify-center rounded-full bg-white w-[100px] h-[100px] overflow-hidden'>
                                                <img src={eligible?.img} alt={eligible?.description} className={`object-cover h-fit flex justify-center items-center p-2 ${eligible?.type ? 'mt-5' : ''}`} w='100' h='100' />
                                            </div>
                                        </div>
                                        <div className='bg-primary flex w-[80%] mx-auto h-[100px] -ml-[18%]'>
                                            <p className='flex justify-center items-center px-4 ml-[20%] text-white md:text-lg text-base'>
                                                {eligible.description2 ? (
                                                    <>
                                                        {eligible.description2[0]}<br />
                                                        {eligible.description2[1]}
                                                    </>
                                                ) : (
                                                    eligible.description
                                                )}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>

                    <div className='col-span-5 lg:col-span-3 order-first lg:order-none' data-aos="fade-left" data-aos-duration="2000">
                        <div id="cdpap-video" className='h-[300px] md:h-[450px] w-full lg:w-[800px] rounded-xl shadow-xl'>
                            <LazyLoadVideo 
                                src="https://www.youtube.com/embed/q_5BEK0CS8w?rel=0"
                                rounded={true} 
                            />
                        </div>
                    </div>
                </div>
            </div>

            <CdpapModal />
            <Chat />
        </div>
    );
};

export default Cdpap;
