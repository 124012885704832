import React, { useState } from "react";
import { IoSearch } from "react-icons/io5";
import nurse from "../../../src/assets/nurse-icon-1.1.png";
import heart from "../../../src/assets/two-heart-icons-large.png";
import NHTD from "../../../src/assets/nhtd-vector.png";
import CARE from "../../../src/assets/privatePay.png";
import createSlug from "../Utils/slugify";
import { Link, NavLink } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import NewLoading from "../NewLoading";

const cardStyle = {
  boxShadow:
    "0px 2px 20px rgba(0, 0, 0, 0.05), 0px 5px 15px rgba(0, 0, 0, 0.05)",
  transition: "box-shadow 0.3s ease-in-out",
  position: "relative",
};

const Categories = ({ handleSearch, handleButtonClick }) => {
  const [inputValue, setInputValue] = useState(""); // Separate state for input value

  const categories = [
    {
      serviceName: "HHA/PCA",
      icon: nurse,
      category: "HHA",
    },
    {
      serviceName: "NHTD",
      icon: NHTD,
      category: "NHTD",
    },
    {
      serviceName: "Private Pay",
      icon: heart,
      category: "PRIVATEPAY",
    },
    {
      serviceName: "CDPAP",
      icon: CARE,
      category: "CDPAP",
    },
  ];

  const options = { year: "numeric", month: "long", day: "numeric" };

  const url = `https://cottage-updated-server-v3.vercel.app/blogs`;

  const {
    data: { blogs, count } = [],
    isLoading: queryLoading,
    refetch,
  } = useQuery({
    queryKey: ["blogs"],
    queryFn: async () => {
      const res = await fetch(url);
      const data = await res.json();
      return data;
    },
  });

  return (
    <div className="w-[90%] lg:w-[80%] 2xl:w-[380px] 2xl:ml-10 mx-auto">
      {/* Categories Section */}
      <div className="mt-10 poppins">
        <h1 className="text-center text-2xl mb-2 afterEffect font-semibold dark:text-white">
          Categories
        </h1>
        <hr className="w-[50px] border-t-[2px] border-primary mx-auto" />
      </div>

      <div className="grid grid-cols-2 bg-white mt-8" style={cardStyle}>
        {categories?.map((category, index) => (
          <div
            onClick={() => handleButtonClick(category?.category)}
            key={index}
            className={`p-4 flex justify-center text-center ${
              index < 2 ? "border-b" : ""
            } ${
              index % 2 === 1 ? "border-l" : ""
            } border-gray-300 cursor-pointer hover:bg-primary hover:text-white category-style`}
          >
            <div>
              <div>
                <img
                  className="w-24 h-24 rounded-full border-[1px] p-4"
                  src={category?.icon}
                  alt={category?.serviceName}
                />
              </div>
              <h1 className="mt-2 font-semibold">{category?.serviceName}</h1>
            </div>
          </div>
        ))}
      </div>

      <h1 className="text-center mt-3 uppercase font-semibold dark:text-gray-100 text-primary cursor-pointer"
      onClick={() => handleButtonClick('OTHERS')}
      
      >
        Others
      </h1>

      {/* Search Option Start */}
      <div>
        <h1 className="text-center text-2xl  font-semibold mt-12 dark:text-white">
          Search
        </h1>
        <hr className="w-[50px] border-t-[2px] border-primary mx-auto" />

        <div className="relative w-full">
          <input
            type="text"
            value={inputValue} // Controlled input for typing
            onChange={(e) => setInputValue(e.target.value)} // Update input value, not searchTerm
            className="mx-auto mt-5 block pr-9 pl-4 py-2.5 focus:outline-none relative w-full"
            placeholder="Search..."
            style={cardStyle}
            required
          />
          <button
            className="absolute top-3 right-3 hover:text-primary text-xl"
            onClick={() => {
              handleSearch(inputValue);
            }}
          >
            <IoSearch />
          </button>
        </div>
      </div>
      {/* Search Option End */}

      {/* Popular Posts Start */}
      <div>
        <h1 className="text-center text-2xl afterEffect font-semibold mt-14 dark:text-white">
          Recent Posts
        </h1>
        <hr className="w-[50px] border-t-[2px] border-primary mx-auto" />

        <div className="mt-5">
          {queryLoading && <NewLoading />}
          {blogs?.slice(0, 3)?.map((blog) => {
            const slug = createSlug(blog?.title); // Generate slug for the blog title

            return (
              <div key={blog?._id}>
                <Link
                  to={`/blogs/${slug}`}
                  target="_blank" // This will open the link in a new tab
                  rel="noopener noreferrer"
                  className="flex gap-5 py-5 border-b-2 border-gray-300 border-dotted"
                >
                  <div className="w-[30%]">
                    <img
                      src={blog?.img}
                      alt={blog?.title}
                      className="w-28 h-20"
                    />
                  </div>
                  <div className="w-[60%]">
                    <h1 className="text-gray-600 font-semibold manual-hyphen leading-tight hover:text-primary dark:hover:text-primary dark:text-gray-200">
                      {blog?.title}
                    </h1>

                    <p className="font-semibold text-sm text-gray-400 mt-1 dark:text-gray-300">
                      {new Date(blog?.newDate).toLocaleString(
                        undefined,
                        options
                      )}
                    </p>
                  </div>
                </Link>
              </div>
            );
          })}
        </div>
      </div>
      {/* Popular Posts End */}
    </div>
  );
};

export default Categories;
