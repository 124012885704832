import React, { useEffect, useState, useContext } from "react";
import { useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import Linkify from "react-linkify";
import { toast } from "react-hot-toast";
import { useQuery } from "@tanstack/react-query";
import { Link, useNavigate } from "react-router-dom";
import logo from "../../../src/assets/Cottage Home.png";
import { AuthContext } from "../Pages/Context/AuthProvider";
import useAdmin from "../Hooks/UseAdmin";
import PageComponent from "../PageComponent/PageComponent";
import NewLoading from "../NewLoading";
import BlogBanner from "./BlogBanner";
import { FaCircleUser } from "react-icons/fa6";
import { PiLineVerticalBold } from "react-icons/pi";


const SingleBlog = () => {
  // const blog = useLoaderData();

  const { slug } = useParams(); // Get slug from route params
  const [blog, setBlog] = useState(null);
  const [loading, setLoading] = useState(true);

  const { user } = useContext(AuthContext);
  const [isAdmin] = useAdmin(user?.email);



  useEffect(() => {
    const fetchBlog = async () => {
      try {
        const response = await fetch(
          `https://cottage-updated-server-v3.vercel.app/blogs/single/${slug}`
        );
        if (!response.ok) {
          throw new Error("Failed to fetch blog");
        }
        const data = await response.json();
        setBlog(data); // Set the fetched blog data
        setLoading(false);
      } catch (error) {
        console.error("Error fetching blog:", error);
        setLoading(false);
      }
    };

    fetchBlog();
  }, [slug]); // Fetch blog data when the slug changes


  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();
  const newDate = Date.now();

  const url = `https://cottage-updated-server-v3.vercel.app/comments/${blog?._id}`;

  // console.log(url)

  const {
    data: comments = [],
    isLoading,
    refetch,
  } = useQuery({
    queryKey: ["comments", blog?._id],
    queryFn: async () => {
      const res = await fetch(url);
      const data = await res.json();
      return data;
    },
  });


  const commentHandler = (data) => {
    const comment = {
      name: data?.fullName,
      email: data?.email,
      message: data?.comment,
      date: newDate,
      blogId: blog?._id,
    };

    // console.log(comment);

    if (blog?._id) {
      fetch("https://cottage-updated-server-v3.vercel.app/comments", {
        method: "POST",
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify(comment),
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.acknowledged) {
            toast.success("Comment Send Successfully");
            reset();
            refetch();
          }
        });
    }
  };

  const deleteHandler = (id) => {
    const proceed = window.confirm(
      "Are you sure, you want to delete this comment"
    );
    if (proceed) {
      fetch(`https://cottage-updated-server-v3.vercel.app/comments/${id}`, {
        method: "DELETE",
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.deletedCount) {
            toast.success("Comment Delete SuccessFully");
            refetch();
          }
        });
    }
  };

  const customComponentDecorator = (href, text, key) => (
    <a
      href={href}
      key={key}
      style={{ color: "blue" }}
      target="_blank"
      rel="noopener noreferrer"
    >
      {text}
    </a>
  );

  const dynamicKeywords = `${blog?.title}, Cottage Home Care blog, healthcare blog, ${blog?.title} article`;
  const dynamicDescription = blog?.description
    ?.map((des) => des.content)
    .join(" ");

    // console.log(dynamicKeywords, dynamicDescription)

  return (
    <div className="min-h-screen dark:bg-slate-600">
      {
       blog &&
        <PageComponent
        title={`${blog?.title} - Cottage Home Care Services`}
        description={dynamicDescription}
        keywords={dynamicKeywords}
      />
      }
     
      <BlogBanner />
      { loading ? (
        <>
          <div className="my-5">
            <NewLoading />
          </div>
        </>
      ) : (
        <>
          <div className="w-[95%] lg:w-[85%] md:w-[90%] mx-auto">
            <div className="md:grid grid-cols-8 gap-7  source-sans">
              <div className="lg:col-span-5 md:col-span-4">
                <div className=" border-b-[1px] border-dashed pb-2 border-gray-400 ">
                  <h1 className="poppins text-lg  md:text-2xl  font-semibold  mt-5 md:mt-8 custom-font  text-gray-700 dark:text-gray-100 ">
                    {blog?.title}
                  </h1>
                </div>

                <div className="mt-3 flex items-center gap-0 md:gap-3">
                  <div className="flex items-center  md:gap-2 gap-1">
                    <p>
                      <FaCircleUser className="text-2xl md:text-4xl text-gray-400 dark:text-gray-200" />
                    </p>
                    <p className="text-gray-500 font-semibold dark:text-gray-100 text-sm md:text-base">
                      By{" "}
                      <span className="text-gray-800 dark:text-gray-300">
                        Admin
                      </span>{" "}
                    </p>
                  </div>

                  <p>
                    <PiLineVerticalBold className=" text-lg md:text-xl text-gray-600 dark:text-gray-100" />
                  </p>

                  <div>
                    {blog?.newDate ? (
                      <p className="  text-[#8c8a98]  font-semibold dark:text-gray-100 text-sm md:text-base">
                        {new Date(blog?.newDate).toLocaleString(undefined, {
                          year: "numeric",
                          month: "long",
                          day: "numeric",
                        })}
                      </p>
                    ) : (
                      <p className="  text-[#8c8a98]  font-semibold dark:text-gray-100 text-sm md:text-base">
                        {blog?.date}
                      </p>
                    )}
                  </div>

                  <p>
                    <PiLineVerticalBold className=" text-lg md:text-xl text-gray-600 dark:text-gray-100" />
                  </p>

                  <div>
                    <p className="flex font-semibold text-gray-500 gap-1 md:gap-2 md:text-base text-sm dark:text-gray-100">
                      <span>{comments?.length}</span>
                      <span>
                        {comments?.length > 1 ? "Comments" : "Comment"}
                      </span>
                    </p>
                  </div>

                  <p>
                    <PiLineVerticalBold className=" text-lg md:text-xl text-gray-600 dark:text-gray-100" />
                  </p>

                  <div>
                    <p className="text-[#8c8a98]  font-semibold dark:text-gray-100 md:text-base text-sm md:text-bas">
                      <span>{blog?.views}</span> <span className="ml-1"> {blog?.views > 1 ? 'Views' : 'View'} </span>
                    </p>
                  </div>
                </div>

                <div className="lg:mt-8 mt-5 overflow-hidden 2xl:h-[600px] lg:h-[450px] md:h-[400px] h-[250px]">
                  <img
                    src={blog?.img}
                    alt={`${blog?.title}`}
                    className="w-full h-full object-cover"
                  />
                </div>

                <div className="lg:mt-10 mt-3">
                  {blog?.description?.map((des, index) => (
                    <div key={index} className="mt-5  px-2 md:px-0">
                      <h2 className="text-xl berlin font-medium dark:text-gray-100">
                        {des?.sub_title}
                      </h2>

                      <div
                        className="text-justify dark:text-gray-100"
                        style={{ whiteSpace: "pre-line" }}
                      >
                        <Linkify componentDecorator={customComponentDecorator}>
                          {des?.content.split("<br/>")?.map((line, i) => (
                            <React.Fragment key={i}>
                              {line}
                              <br />
                            </React.Fragment>
                          ))}
                        </Linkify>
                      </div>
                    </div>
                  ))}
                </div>
              </div>

              <div className="lg:col-span-3 md:col-span-4 mt-[5vh]">
                <div className="border-[1.5px] min-h-[30rem] lg:w-[90%] w-[95%] mx-auto border-t-primary hidden md:block">
                  <p className="text-center font-semibold py-3 border-b-[1.5px] text-sm dark:text-gray-100">
                    ABOUT
                  </p>

                  <div className="w-[70%] mx-auto bg-[#EBF8F9] mt-8 py-8 rounded-md">
                    <div className="flex justify-center">
                      <img
                        src={logo}
                        alt=""
                        className="w-[50%] h-[50%]"
                        w="100"
                        h="100"
                      />
                    </div>
                  </div>
                  <div className="mx-auto px-2 text-lg mt-3 text-center w-[80%] text-[#6c6262]">
                    <p className="font-medium dark:text-gray-100">
                      Cottage Home Care Services. 516.367.2266. The Right Home
                      Care for You . HHA/PCA, NHTD, Private PAY & CDPAP Services
                      throughout New York City
                    </p>
                  </div>

                  <div className="text-center mt-4">
                    <Link to="/">
                      <button className="bg-gray-50 px-3 py-3 shadow-md text-lg text-primary uppercase font-semibold bg-hov2">
                        Read More
                      </button>
                    </Link>
                  </div>
                </div>
                <div className="mt-14 border-[1.5px] lg:w-[90%] w-[95%] mx-auto border-t-primary bg-[#f2f4f6de] py-3 pb-14 dark:bg-slate-800">
                  <p className="text-center font-semibold mt-8 text-lg  berlin dark:text-gray-100 custom-font">
                    COMMENT SECTION
                  </p>
                  <hr className="border-[#00a6b2] border-t-[1.5px] w-12 mt-1 mx-auto" />

                  <div className="w-[90%] mx-auto mt-10">
                    <form onSubmit={handleSubmit(commentHandler)}>
                      <div className="space-y-3">
                        <div>
                          <input
                            type="text"
                            {...register("fullName", {
                              required: "FullName is required",
                            })}
                            id="fullName"
                            placeholder="Your Name"
                            className="input w-full focus:outline-none input-bordered input-primary dark:bg-gray-400 dark:placeholder:text-gray-100 dark:text-gray-100"
                          />
                          {errors.fullName && (
                            <p className="text-red-600">
                              {errors.fullName.message}
                            </p>
                          )}
                        </div>

                        <div>
                          <input
                            type="email"
                            {...register("email", {
                              required: "email is required",
                            })}
                            placeholder="Your Email"
                            className="input w-full focus:outline-none input-bordered input-primary dark:bg-gray-400 dark:placeholder:text-gray-100 dark:text-gray-100"
                          />

                          {errors.email && (
                            <p className="text-red-600">
                              {errors.email.message}
                            </p>
                          )}
                        </div>

                        <div>
                          <textarea
                            type="text"
                            {...register("comment", {
                              required: "Message is required",
                            })}
                            className="textarea textarea-primary w-full focus:outline-none dark:bg-gray-400 dark:placeholder:text-gray-100 dark:text-gray-100"
                            placeholder="Your Message"
                          ></textarea>

                          {errors.comment && (
                            <p className="text-red-600">
                              {errors.comment.message}
                            </p>
                          )}
                        </div>

                        <button className="input w-full focus:outline-none bg-primary hover:bg-secondary text-white uppercase text-lg font-medium">
                          Submit
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>

            <div className="pt-2 pb-10 md:w-[62%]">
              <div className="mt-8 grid grid-cols-5 items-center">
                <div className="col-span-2">
                  <hr className="border-t-[1px]" />
                </div>
                <div>
                  <p className="font-semibold text-center uppercase dark:text-gray-100 text-xs md:text-base">
                    <span className="text-red-400 md:mr-1.5">
                      {comments?.length}
                    </span>
                    comments
                  </p>
                </div>

                <div className="col-span-2">
                  <hr className="border-t-[1px]" />
                </div>
              </div>
              {comments?.map((comment) => (
                <div key={comment?._id}>
                  <div className="mt-5 text-[#6c6262] py-5 border-[1px] rounded p-5 border-primary dark:border-gray-100">
                    <p className="mb-1 text-red-400 dark:text-gray-100">
                      {new Date(comment?.date).toLocaleString()}
                    </p>
                    <div className="flex items-center justify-between">
                      <div>
                        <h1 className="text-lg font-medium dark:text-gray-100">
                          {comment?.name}
                        </h1>
                      </div>
                      <div>
                        <i className="text-sm dark:text-gray-100">
                          {comment?.email}
                        </i>
                      </div>
                    </div>

                    <p className="mt-2 md:w-10/12 dark:text-gray-100">
                      <span className="mr-2">Message:</span>
                      {comment?.message}
                    </p>

                    {user && isAdmin && (
                      <div className="mt-2">
                        <button
                          className="btn btn-sm bg-red-500 rounded-md shadow-md"
                          onClick={() => deleteHandler(comment?._id)}
                        >
                          Delete
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default SingleBlog;
