import React from "react";
import "../NewsLetter/NewsLetter.css";
import MailchimpSubscribe from "react-mailchimp-subscribe";
import Loading from "../Shared/Loading/Loading";

const cardStyle = {
    
    padding: "20px",
    boxShadow:
      "0px 2px 50px rgba(0, 0, 0, 0.05), 0px 10px 30px rgba(0, 0, 0, 0.05)",
    transition: "box-shadow 0.3s ease-in-out",
  };
  

const Subscribe = () => {
  const url = process.env.REACT_APP_mailchimp;

  // console.log(url)

  const SimpleForm = () => <MailchimpSubscribe url={url} />;

  return (
    <div className=" w-full  pb-12 pt-5 absolute  bottom-0">
      <div className="w-[95%] 2xl:w-[80%] mx-auto mt-3 block  lg:p-6 bg-slate-200 dark:bg-slate-600 rounded-md"


    style={cardStyle}
      
      >
        <h1 className=" text-lg md:text-2xl mt-5  font-bold text-gray-500 dark:text-gray-100   text-center ">
          Stay Connected with Our Newsletter!
        </h1>
        <h5 className="  text-center mt-2 tracking-wide text-gray-500 dark:text-gray-100 font-medium ">
          Get the latest newsletter on home care tips, news, and exclusive
          offers.
        </h5>
        <div className="flex justify-center items-center mt-5 gap-5">
          <div className="lg:w-[80%]  w-[95%] pb-5 mx-auto">
            <MailchimpSubscribe
              url={url}
              render={({ subscribe, status, message }) => (
                <div className="news-letter  ">
                  <SimpleForm onSubmitted={(formData) => subscribe(formData)} />

                  {status === "sending" && (
                    <div className="text-primary">
                      <Loading />
                    </div>
                  )}
                  {status === "error" && (
                    <div dangerouslySetInnerHTML={{ __html: message }} />
                  )}
                  {status === "success" && (
                    <div className="text-primary">Subscribed !</div>
                  )}
                </div>
              )}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Subscribe;
